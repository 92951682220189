import plugin from 'tailwindcss/plugin';
import { areElementsRounded } from './src/settings.json';
import { type BaseFontConfigs, type IFontConfig, FONT_WEIGHTS } from './src/types/fonts';

export const fontConfig: Record<BaseFontConfigs, IFontConfig> = {
	headingDesktop: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.EXTRABOLD,
		fontStyle: 'normal',
		fontSize: 36,
		lineHeight: 42,
		uppercase: true,
	},
	headingMobile: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.EXTRABOLD,
		fontStyle: 'normal',
		fontSize: 24,
		lineHeight: 26,
		uppercase: true,
	},
	subheading: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.EXTRABOLD,
		fontSize: 18,
		fontStyle: 'normal',
		lineHeight: 26,
		uppercase: true,
	},
	bodyLargeBold: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.BOLD,
		fontSize: 16,
		fontStyle: 'normal',
		lineHeight: 24,
		uppercase: false,
	},
	bodyLarge: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.MEDIUM,
		fontSize: 16,
		fontStyle: 'normal',
		lineHeight: 24,
		uppercase: false,
	},
	bodySmall: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.MEDIUM,
		fontSize: 12,
		fontStyle: 'normal',
		lineHeight: 18,
		uppercase: false,
	},
	navigationLarge: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.BOLD,
		fontSize: 22,
		fontStyle: 'normal',
		lineHeight: 24,
		uppercase: true,
	},
	navigationSmall: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.EXTRABOLD,
		fontSize: 16,
		fontStyle: 'normal',
		lineHeight: 16,
		uppercase: true,
	},
	button: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.BOLD,
		fontSize: 16,
		fontStyle: 'normal',
		lineHeight: 30,
		uppercase: true,
	},
	featureLabel: {
		fontFamily: 'brother-1816',
		fontWeight: FONT_WEIGHTS.EXTRABOLD,
		fontSize: 12,
		fontStyle: 'normal',
		lineHeight: 12,
		uppercase: true,
	},
};

const brandColors = {
	'brand-01': '#000000',
	'brand-02': '#000',
	'brand-03': '#353535',
	'brand-04': '#DDDDDD',
	'brand-05': '#fff',
	'brand-06': '#393939',
	'brand-07': '#FF7F9B',
};

const colorConfig = {
	text: {
		primary: brandColors['brand-01'],
		secondary: brandColors['brand-06'],
		active: brandColors['brand-02'],
		link: brandColors['brand-02'],
		'link-hover': brandColors['brand-03'],
		'icon-primary': brandColors['brand-02'],
		'icon-hover': brandColors['brand-03'],
		'icon-secondary': brandColors['brand-05'],
		error: brandColors['brand-07'],
	},
	background: {
		root: '#000',
		primary: brandColors['brand-05'],
		secondary: brandColors['brand-01'],
		tertiary: brandColors['brand-02'],
		highlight: '#393939',
		input: brandColors['brand-06'],
		button: brandColors['brand-02'],
		'button-hover': brandColors['brand-03'],
	},
	border: {
		input: brandColors['brand-04'],
		error: brandColors['brand-07'],
		hover: brandColors['brand-02'],
		active: brandColors['brand-02'],
		/**
		 * Adding alpha channel to the hex code - 40% opacity
		 * 10% opacity: 1A
		 * 20% opacity: 33
		 * 30% opacity: 4D
		 * 40% opacity: 66
		 * 50% opacity: 80
		 * 60% opacity: 99
		 * 70% opacity: B3
		 * 80% opacity: CC
		 * 90% opacity: E6
		 * 100% opacity: FF
		 */
		focus: `${brandColors['brand-03']}66`,
	},
};

const fontConfigFactory = (fontDeclaration: IFontConfig) => {
	return {
		'font-family': fontDeclaration.fontFamily,
		'font-weight': `${fontDeclaration.fontWeight}`,
		'font-size': `${fontDeclaration.fontSize}px`,
		// Convert the line height to relative units
		'line-height': `${fontDeclaration.lineHeight / fontDeclaration.fontSize}`,
		'text-transform': fontDeclaration.uppercase ? 'uppercase' : 'none',
	};
};

const inputCornerRadius = areElementsRounded ? '8px' : '0px';

export default {
	content: ['./src/**/*.{astro,html,js,jsx,md,mdx,svelte,ts,tsx,vue}'],
	theme: {
		extend: {
			boxShadow: {
				layout: areElementsRounded ? '0px 20px 50px #00000033' : 'none',
				'input-error-panel': '0 3px 20px #00000029',
			},
			screens: {
				zero: '0px',
				xs: '340px',
			},
			borderRadius: {
				button: areElementsRounded ? '8px' : '0px',
				checkbox: areElementsRounded ? '5px' : '0px',
				// This is for setting the rounding on the body tile
				layout: inputCornerRadius,
				input: inputCornerRadius,
				'file-input': inputCornerRadius,
				countdown: inputCornerRadius,
				'entry-code': inputCornerRadius,
				'winner-row': inputCornerRadius,
				accordion: inputCornerRadius,
			},
			width: {
				// Utility class for touch targets. Based on Apple's HIG
				'touch-target': '44px',
				checkbox: '24px',
				'entry-code': '317px',
			},
			minWidth: {
				button: '200px',
			},
			minHeight: {
				button: '60px',
			},
			height: {
				'touch-target': '44px',
				checkbox: '24px',
				input: '60px',
				'file-input': '102px',
				'entry-code': '80px',
			},
		},
	},
	plugins: [
		plugin(({ addUtilities }) => {
			addUtilities({
				':root': {
					/**
					 * COLORS
					 */
					'--brand-01': brandColors['brand-01'],
					'--brand-02': brandColors['brand-02'],
					'--brand-03': brandColors['brand-03'],
					'--brand-04': brandColors['brand-04'],
					'--brand-05': brandColors['brand-05'],
					'--brand-06': brandColors['brand-06'],
					'--brand-07': brandColors['brand-07'],
					/**
					 * TEXT COLORS
					 */
					'--text-primary': colorConfig.text.primary,
					'--text-secondary': colorConfig.text.secondary,
					'--text-active': colorConfig.text.active,
					'--text-link': colorConfig.text.link,
					'--text-link-hover': colorConfig.text['link-hover'],
					'--text-icon-primary': colorConfig.text['icon-primary'],
					'--text-icon-hover': colorConfig.text['icon-hover'],
					'--text-icon-secondary': colorConfig.text['icon-secondary'],
					'--text-error': colorConfig.text.error,
					/**
					 * BACKGROUND COLORS
					 * These values may be gradients. If you want to support gradients,
					 * make sure you use `background` and not `background-color`
					 * when you reference these variables
					 */
					'--background-root': colorConfig.background.root,
					'--background-primary': colorConfig.background.primary,
					'--background-secondary': colorConfig.background.secondary,
					'--background-tertiary': colorConfig.background.tertiary,
					'--background-highlight': colorConfig.background.highlight,
					'--background-input': colorConfig.background.input,
					'--background-button': colorConfig.background.button,
					'--background-button-hover': colorConfig.background['button-hover'],
					/**
					 * BORDER COLORS
					 */
					'--border-input': colorConfig.border.input,
					'--border-error': colorConfig.border.error,
					'--border-hover': colorConfig.border.hover,
					'--border-active': colorConfig.border.active,
					'--border-focus': colorConfig.border.focus,
					/**
					 * Input Styles
					 */
					'--input-border': colorConfig.border.input,
					'--input-border-error': colorConfig.border.error,
					'--input-border-hover': colorConfig.border.hover,
					'--input-border-active': colorConfig.border.active,
					'--input-label': '#fff',
					'--input-label-error': colorConfig.text.error,
					'--input-label-active': '#fff',
					'--input-background': colorConfig.background.input,
					'--input-placeholder': colorConfig.text.secondary,
					/**
					 * Form Progress
					 */
					'--form-progress-node-default': colorConfig.background.secondary,
					'--form-progress-node-active': colorConfig.background.tertiary,
					'--form-progress-node-past': colorConfig.background.tertiary,
				},
				/**
				 * TYPOGRAPHY
				 */
				'.font-heading-desktop': fontConfigFactory(fontConfig.headingDesktop),
				'.font-heading-mobile': fontConfigFactory(fontConfig.headingMobile),
				'.font-subheading': fontConfigFactory(fontConfig.subheading),
				'.font-large-body-bold': fontConfigFactory(fontConfig.bodyLargeBold),
				'.font-large-body': fontConfigFactory(fontConfig.bodyLarge),
				'.font-small-body': fontConfigFactory(fontConfig.bodySmall),
				'.font-navigation-large': fontConfigFactory(fontConfig.navigationLarge),
				'.font-navigation-small': fontConfigFactory(fontConfig.navigationSmall),
				'.font-button': fontConfigFactory(fontConfig.button),
				'.font-feature-label': fontConfigFactory(fontConfig.featureLabel),
			});
		}),
	],
};
