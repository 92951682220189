import { regionLabels } from '~/hooks/useVotes';
import { useFocusedRegion } from './useFocusedRegion';
import { useEffect, useRef } from 'react';
import { useBreakpointEffect } from '~/hooks/useTailwindBreakpoint';

export const RegionFocuser = () => {
	const [focusedRegion, setFocusedRegion] = useFocusedRegion();

	// you can only focus on a region on mobile,
	// so we reset the focused region when the screen is (or becomes) large
	useBreakpointEffect('lg', (doesMatch: boolean) => {
		if (doesMatch) {
			setFocusedRegion(null);
		}
	});

	return (
		<select
			value={focusedRegion || 'all'}
			disabled={focusedRegion === null}
			onChange={e => setFocusedRegion(e.target.value)}
			className="bg-gray-600 text-white px-4 py-2 w-full mb-6 lg:hidden"
		>
			{[...regionLabels.entries()].map(([region, label]) => {
				return (
					<option key={region} value={region}>
						{label}
					</option>
				);
			})}
		</select>
	);
};
