export type FontWeightConfiguration = [0 | 1, number];
export interface IFontWeightMapping {
	[key: string]: FontWeightConfiguration[];
}

export type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export enum FONT_WEIGHTS {
	THIN = 100,
	EXTRALIGHT = 200,
	LIGHT = 300,
	REGULAR = 400,
	MEDIUM = 500,
	SEMIBOLD = 600,
	BOLD = 700,
	EXTRABOLD = 800,
	BLACK = 900,
}

export type FontFamily =
	| 'Roboto'
	| 'Montserrat'
	| 'Oswald'
	| 'Roboto+Slab'
	| 'Varela+Round'
	| 'Bebas+Neue'
	| 'Nunito'
	| 'Archivo'
	| 'Inter'
	| 'Lato'
	| 'Noto+Sans'
	| string;

export type FontWeightAsText =
	| 'thin'
	| 'extralight'
	| 'light'
	| 'regular'
	| 'medium'
	| 'semibold'
	| 'bold'
	| 'extrabold'
	| 'black';

export type FontDeclaration = {
	fontFamily: string;
	fontWeight: number;
	fontStyle: 'italic' | 'normal';
};

export type IFontManifest = Record<BaseFontConfigs, IFontConfig>;

export interface IFontConfig {
	fontFamily: FontFamily;
	fontWeight: FontWeight;
	fontSize: number;
	fontStyle: 'italic' | 'normal';
	lineHeight: number;
	uppercase: boolean;
}

export type BaseFontConfigs =
	| 'headingDesktop'
	| 'headingMobile'
	| 'subheading'
	| 'bodyLargeBold'
	| 'bodyLarge'
	| 'bodySmall'
	| 'navigationLarge'
	| 'navigationSmall'
	| 'button'
	| 'featureLabel';
